import { signal }           from '@preact/signals';

import { HUES_MAX }         from './constants.ts';


const getInRange = ( value: number ) =>
    value < 0
        ? HUES_MAX + value
        : value % HUES_MAX;


export const getRandomHue = () =>
    Math.round( HUES_MAX * Math.random() );


export const hues =         signal<number[]>([ getRandomHue() ]); 


const getInsertGap = ( index: number ) =>
    hues.value.length
        ? ( index === 0 || index === hues.value.length )
            ? hues.value[0] + HUES_MAX - ( hues.value.at( -1 ) as number )
            : hues.value[ index ] - hues.value[ index - 1 ]
        : HUES_MAX;


export const insertHues = ( partCount: number, index: number ) => {

    const baseValue = 
        index in hues.value
            ? hues.value[ index ]
            : hues.value[ 0 ] || 0;
    const step =        getInsertGap( index ) / ( partCount + 1 );

    const newHues =     [ ...hues.value ];
    for( let i = 1; i <= partCount; i++ ){
        newHues.push( getInRange(
            baseValue - i * step
        ));
    }
    newHues.sort(( a, b ) => a - b );

    hues.value =        newHues;
};

export const removeHue = ( index: number ) =>
    hues.value = hues.value.toSpliced( index, 1 );

export const setHue = ( index: number, value: number ) =>
    hues.value = hues.value.toSpliced(
        index,
        1,
        getInRange( value ),
    );
