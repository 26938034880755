import CopyValue            from '../copy-value/CopyValue';
import { hues }             from '../hues/index.ts';


export default function PaletteSave(){
    return (
        <div class="com-palette-save">
            <CopyValue value={ hues.value.join( ' ' ) } />
        </div>
    );
}
