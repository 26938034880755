import { TargetedEvent }    from 'preact/compat';

import '../layouts/inline-controls.css';

import {
    cellType,
    setCellType,
}                           from './state.ts';
import {
    PALETTE_CELL_TYPES,
}                           from './constants.ts';


const onChange = ( evt: TargetedEvent<HTMLSelectElement> ) =>
    setCellType( evt.currentTarget.value );


export default function PaletteCellTypeChooser() {
    return (
        <div class="com-palette-cell-type-chooser layouts-inline-controls">
            <span>Cells:</span>
            <select onChange={ onChange } value={ cellType.value }>
                { PALETTE_CELL_TYPES.map( type =>
                    <option
                        children={ type }
                        key={ type }
                        value={ type }
                    />
                )}
            </select>
        </div>
    );
}
