import { render }           from 'preact';

import '../background/sync-css-vars.ts';

import App                  from './App.tsx';
import './global.css';

render(
    <App />,
    document.getElementById( 'app-main' ) !,
);
