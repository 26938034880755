import { signal }           from '@preact/signals';

import { CHROMA_MAX }       from './constants.ts';


export const globalMax =    signal<number>( CHROMA_MAX );

export const setGlobalMax = ( newValue: number ) =>
    globalMax.value = Math.min(
        CHROMA_MAX,
        Math.max( 0, newValue ),
    );


export const chroma =       signal<number>( 0.16 );


export const setChroma = ( newValue: number ) =>
    chroma.value = Math.min(
        CHROMA_MAX,
        Math.max( 0, newValue ),
    );
