import { signal }           from '@preact/signals';

import {
    BACKGROUND_DARK,
    BACKGROUND_DEFAULT,
    BACKGROUND_LIGHT,
}                           from './constants.ts';


export const background =   signal<string>( BACKGROUND_DEFAULT );


export const setBackground = ( newBackground: string ) =>
    background.value =      newBackground;

export const setDarkBackground = () =>
    setBackground( BACKGROUND_DARK );

export const setLightBackground = () =>
    setBackground( BACKGROUND_LIGHT );
