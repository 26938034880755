import {
    inGamut,
    modeRgb,
    modeOklch,
    type Oklch,
    useMode,
}                           from '../culori/index.mjs';

import { CHROMA_STEP }      from './constants.ts';


useMode( modeRgb );
useMode( modeOklch );

const inRgb =               inGamut( 'rgb' );


export default ( hue: number, lightness: number ): number => {
    const color = {
        mode:               'oklch',
        h:                  hue,
        l:                  lightness / 100,
        c:                  0,
    } as Oklch;

    while( inRgb( color )){
        color.c +=          CHROMA_STEP;
    }

    return Math.max(
        0,
        color.c - CHROMA_STEP,
    );
}
