import { insertHues }       from './state.ts';


export default function HuesInsertButton({
    amount,
    index,
}: {
    amount:                 number,
    index:                  number,
}
){
    return <button
        children={ amount === 1 ? '↓' : '⇊' }
        onClick={ () =>
            insertHues( amount, index )
        }
        title={
            `insert ${ amount } hue${ amount > 1 ? 's' : '' } here`
        }
    />;
}
