import { TargetedEvent }    from 'preact/compat';

import {
    globalMax,
    setGlobalMax,
}                           from './state.ts';
import {
    CHROMA_MAX,
    CHROMA_STEP,
}                           from './constants.ts';


const onChange = ( evt: TargetedEvent<HTMLInputElement> ) =>
    setGlobalMax( parseFloat( evt.currentTarget.value ));


export default function ChromaGlobalLimiter({
    className,
}:{
    className?:              string,
}) {
    return <input
        class={ className }
        max={ CHROMA_MAX }
        min="0"
        onInput={ onChange }
        step={ CHROMA_STEP }
        type="range"
        value={ globalMax.value }
    />
}
