import clsx                 from 'clsx';

import {
    CHROMA_MAX,
    CHROMA_STEP,
}      from '../chroma/index.ts';
import '../layouts/inline-controls.css';

import {
    lightnessChromas,
    removeChromaLimit,
    setChromaLimit,
}                           from './state.ts';
import './ChromaLimiter.css';


export default function PaletteChromaLimiter({
    lightnessIndex,
}:{
    lightnessIndex:         number,
}){
    const {
        maxChroma,
        minChroma,
        limit,
    } = lightnessChromas.value[ lightnessIndex ];
    const isLargeGap =      maxChroma / minChroma > 2;
    return (
        <div class="com-palette-chroma-limiter layouts-inline-controls">
            <span
                children={ minChroma.toFixed( 3 ) }
                onClick={ () =>
                    setChromaLimit( lightnessIndex, minChroma )
                }
            />
            <input
                class={ clsx(
                    limit === null && 'no-limit',
                )}
                min="0"
                max={ CHROMA_MAX }
                onChange={ evt =>
                    setChromaLimit(
                        lightnessIndex,
                        parseFloat( evt.currentTarget.value ),
                    )
                }
                step={ CHROMA_STEP }
                type="number"
                value={ limit ?? maxChroma.toFixed( 3 ) }
            />
            <span
                children={ maxChroma.toFixed( 3 ) }
                class={ clsx(
                    'max-value',
                    isLargeGap && 'is-large-gap',
                )}
                onClick={ () =>
                    removeChromaLimit( lightnessIndex )
                }
                title={ isLargeGap
                    ? 'Chroma difference is quite large'
                    : undefined
                }
            />
        </div>
    );
}
