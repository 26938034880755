import { TargetedEvent }    from 'preact/compat';

import '../layouts/inline-controls.css';

import {
    background,
    setBackground,
    setDarkBackground,
    setLightBackground,
}                           from './state.ts';
import './Chooser.css';


const onChangeBackground = ( evt: TargetedEvent<HTMLInputElement> ) =>
    setBackground( evt.currentTarget.value );


export default function BackgroundChooser() {
    return (
        <div class="com-background-chooser layouts-inline-controls">
            <span>Background:</span>
            <button children="dark" onClick={ setDarkBackground } />
            <button children="light" onClick={ setLightBackground } />
            <input
                onChange={ onChangeBackground }
                type="color"
                value={ background.value }
            />
        </div>
    );
}
