import { CLIPBOARD }        from './constants.ts';
import './CopyValue.css';


export default function CopyValue({
    onCopy,
    value,
}:{
    onCopy?:                ( e: Event, v?: string ) => void,
    value:                  string,
}){
    return (
        <div class="com-copy-value">
            <span>{ value }</span>
            <button
                children={ CLIPBOARD }
                onClick={ ( evt: Event ) => {
                    navigator.clipboard.writeText( value );
                    onCopy?.( evt, value );
                }}
                title="copy"
            />
        </div>
    );
}
