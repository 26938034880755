import {
    formatHex,
    formatRgb,
}                           from 'culori/fn';
import { useCallback }      from 'preact/hooks';
import { useSignal }        from '@preact/signals';

import {
    CopyMultipleValues,
}                           from '../copy-value/index.ts';
import Dialog               from '../dialog/Dialog';
import {
    getMaxChroma,
    chromaGlobalMax,
}                           from '../chroma/index.ts';

import {
    cellType,
    chromaLimits,
}                           from './state.ts';
import './Cell.css';


export default function PaletteCell({
    hue,
    lightness,
    lightnessIndex,
}:{
    hue:                    number,
    lightness:              number,
    lightnessIndex:         number,
}){
    const chromaValue = Math.min(
        chromaGlobalMax.value,
        chromaLimits.value[lightnessIndex] ?? Infinity,
        getMaxChroma( hue, lightness )
    );
    const chromaText =      chromaValue.toFixed( 3 );
    const oklch =           `oklch( ${ lightness }% ${ chromaText } ${ hue } )`;
    const values = [
        oklch,
        formatHex( oklch ),
        formatRgb( oklch ),
    ].filter( Boolean );

    const isDialogOpen =    useSignal<boolean>( false );
    const closeDialog = useCallback(
        ( evt: Event ) => {
            evt.stopPropagation();
            isDialogOpen.value = false;
        },
        [ isDialogOpen ],
    );

    return (
        <div
            class="com-palette-cell"
            onClick={ () => {
                isDialogOpen.value = true;
            }}
            style={{
                background:
                    cellType.value === 'color'
                        ? oklch
                        : 'transparent',
                color:          oklch,
            }}
            title={ values.join( '\n' )}
        >
            <span class="label">{
                cellType.value === 'chroma'
                    ? chromaText
                    : 'milliliters'
            }</span>
            <Dialog
                className="dialog"
                isOpen={ isDialogOpen.value }
                onClose={ closeDialog }
            >
                <div class="color" style={{
                    background: oklch,
                }} />
                <CopyMultipleValues
                    onCopy={ closeDialog }
                    values={ values as string[] }
                />
            </Dialog>
        </div>
    );
}
