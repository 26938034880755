import { removeHue }        from './state.ts';


export default function HuesRemoveButton({
    index,
}:{
    index:                  number,
}){
    return <button
        children="🗑"
        onClick={ () => removeHue( index ) }
        title="remove hue"
    />;
}
