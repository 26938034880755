import clsx                 from 'clsx';
import {
    ComponentChildren,
}                           from 'preact';
import {
    useCallback,
    useEffect,
    useRef,
}                           from 'preact/hooks';

import './Dialog.css';


export default function Dialog({
    children,
    className,
    isOpen,
    onClose,
}:{
    children:               ComponentChildren,
    className?:             string,
    isOpen:                 boolean,
    onClose:                ( e: Event ) => void,
}){
    const ref =             useRef<HTMLDialogElement>( null );

    const onClick = useCallback(( event: MouseEvent ) => {
        const dialog =      ref.current;
        if( dialog ){
            const rect =        ref.current.getBoundingClientRect();
            const isInDialog = (
                rect.top <= event.clientY
                && event.clientY <= rect.top + rect.height
                && rect.left <= event.clientX
                && event.clientX <= rect.left + rect.width
            );
            if( ! isInDialog ){
                dialog.close();
            }
        }
    }, [ ref ]);

    useEffect(() => {
        ref.current?.addEventListener( 'close', onClose );
        return () =>
            ref.current?.removeEventListener( 'close', onClose );
    }, [ onClose ] );

    useEffect(() => {
        if( isOpen && ! ref.current?.open ){
            ref.current?.showModal();
        } else if( ! isOpen && ref.current?.open ){
            ref.current?.close();
        }
    }, [ isOpen ]);

    return (
        <dialog
            className={ clsx(
                'com-dialog',
                className,
            )}
            onClick={ onClick }
            ref={ ref }
        >
            { children }
            <button
                children="⛌"
                class="close"
                onClick={ onClose }
                title="close"
            />
        </dialog>
    );
}
