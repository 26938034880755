import { TargetedEvent }    from 'preact/compat';

import { setHue }           from './state.ts';
import './Input.css';


export default function HuesInput({
    hue,
    index,
}: {
    hue:                    number,
    index:                  number,
}){
    return <input
        class="com-hues-input"
        onInput={ ( evt: TargetedEvent<HTMLInputElement> ) =>
            setHue(
                index,
                parseInt( evt.currentTarget.value, 10 ),
            )
        }
        type="number"
        value={ hue }
    />;
}
