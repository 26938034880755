import { computed, signal } from '@preact/signals';

import { getMaxChroma }     from '../chroma/index.ts';
import { hues }             from '../hues/state.ts';
import { LIGHTNESS_PERCENT } from '../lightness/constants.ts';

import {
    PALETTE_DEFAULT_CELL_TYPE,
}                           from './constants.ts';


export const cellType =     signal<string>( PALETTE_DEFAULT_CELL_TYPE );

export const setCellType = ( newValue: string ) =>
    cellType.value =        newValue;


export const chromaLimits = signal<Array<null|number>>(
    LIGHTNESS_PERCENT.map( () =>
        null
    )
);

export const setChromaLimit = (
    index:                  number,
    newValue:               null | number,
) =>
    chromaLimits.value =
        chromaLimits.value.toSpliced( index, 1, newValue );

export const removeChromaLimit = ( index: number ) =>
    setChromaLimit( index, null );


export const lightnessChromas = computed(() => {

    const hueValues =       hues.value;
    const limits =          chromaLimits.value;

    return LIGHTNESS_PERCENT
        .map( lightness =>
            hueValues.map( hue =>
                getMaxChroma( hue, lightness )
            )
        ).map(( chromas, index ) => ({
            minChroma:      Math.min.apply( null, chromas ),
            maxChroma:      Math.max.apply( null, chromas ),
            limit:          limits[index],
        }));
});
