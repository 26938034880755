import { effect }           from '@preact/signals';

import { background }       from './state.ts';
import {
    BACKGROUND_DARK,
    BACKGROUND_LIGHT,
}                           from './constants.ts';


effect(() => {

    const rootStyle =       document.documentElement.style;
    const value =           background.value;

    rootStyle.setProperty( '--color-background', value );

    switch( value ){
    case BACKGROUND_DARK:
        rootStyle.setProperty( 'color-scheme', 'dark' );
        rootStyle.setProperty( '--color-text', BACKGROUND_LIGHT );
        break;
    case BACKGROUND_LIGHT:
        rootStyle.setProperty( 'color-scheme', 'light' );
        rootStyle.setProperty( '--color-text', BACKGROUND_DARK );
        break;
    }
});
