import { CLIPBOARD }        from './constants.ts';
import './Multiple.css';


export default function CopyValueMultiple({
    onCopy,
    values,
}:{
    onCopy?:                ( e: Event, v?: string ) => void,
    values:                 string[],
}){
    return (
        <table class="com-copy-value-multiple">
            { values.map( value =>
                <tr key={ value }>
                    <td>{ value }</td>
                    <td><button
                        children={ CLIPBOARD }
                        onClick={ ( evt: Event ) => {
                            navigator.clipboard.writeText( value );
                            onCopy?.( evt, value );
                        }}
                        title="copy"
                    /></td>
                </tr>
            )}
        </table>
    );
}
