import {
    ChromaGlobalLimiter,
}                           from '../chroma/index.ts';
import ChromaLimiter        from '../palette/ChromaLimiter.tsx';
import {
    LIGHTNESS_PERCENT,
    LIGHTNESS_STEPS,
}                           from '../lightness/index.ts';
import PaletteCell          from '../palette/Cell.tsx';
import '../layouts/inline-controls.css';

import {
    hues,
    InputHue,
    InsertHue,
    RemoveHue,
}                           from '../hues/index.ts';
import './HuesTable.css'


export default function HuesTable(){
    const hueValues =       hues.value;
    return (
        <table class="hues-table">
            <thead class="labels">
                <tr>
                    <td>Lightness</td>
                    <td colspan={ hueValues.length + 2 }>
                        Hues
                    </td>
                    <td>Chroma limits</td>
                </tr>
            </thead>
            <thead class="controls">
                <tr>
                    <td/>
                    <td class="hue">
                        <InsertHue amount={ 1 } index={ 0 } />
                    </td>
                    { hues.value.map(( hue, hueIndex ) =>
                        <td class="hue layouts-inline-controls" key={ hueIndex }>
                            <InsertHue amount={ 2 } index={ hueIndex } />
                            <InputHue hue={ hue } index={ hueIndex } />
                            <InsertHue amount={ 1 } index={ hueIndex + 1 } />
                        </td>
                    ) }
                    <td class="hue">
                        <InsertHue amount={ 2 } index={ hues.value.length } />
                    </td>
                    <td class="chroma-limiter">
                        <ChromaGlobalLimiter className="limiter" />
                    </td>
                </tr>
            </thead>
            <tbody>
                { LIGHTNESS_PERCENT.map(( l, lIndex ) =>
                    <tr key={ lIndex }>
                        <td title={ `${ l }%` }>
                            { LIGHTNESS_STEPS[lIndex] }
                        </td>
                        <td/>
                        { hues.value.map(( hue, hueIndex ) =>
                            <td key={ hueIndex }>
                                <PaletteCell
                                    lightness={ l }
                                    lightnessIndex={ lIndex }
                                    hue={ hue }
                                />
                            </td>
                        )}
                        <td/>
                        <td>
                            <ChromaLimiter lightnessIndex={ lIndex } />
                        </td>
                    </tr>
                )}
            </tbody>
            <tfoot><tr>
                <td/>
                <td/>
                { hues.value.map(( _, index ) =>
                    <td key={ index }>
                        <RemoveHue index={ index } />
                    </td>
                )}
                <td/>
                <td />
            </tr></tfoot>
        </table>
    );
}
