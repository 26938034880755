export const LIGHTNESS_STEPS = `
    1/12
    3/24
    2/12
    5/24
    3/12
    4/12
    5/12
    6/12
    7/12
    8/12
    9/12
    10/12
    11/12
    23/24
    47/48
    95/96
`.trim()
.split( '\n' )
.map( l => l.trim() );

export const LIGHTNESS_VALUES = LIGHTNESS_STEPS
    .map( step => step.split( '/' ))
    .map( parts => parseInt( parts[0] ) / parseInt( parts[1] ));


export const LIGHTNESS_PERCENT = LIGHTNESS_VALUES
    .map( value =>
         Math.round( value * 1e4 ) / 100
    );
