import BackgroundChooser    from '../background/Chooser.tsx';
import favicon              from '../favicon/favicon.svg';
import HuesTable            from '../hues-table/HuesTable.tsx';
import PaletteCellTypeChooser   from '../palette/CellTypeChooser.tsx';
import PaletteSave          from '../palette/Save.tsx';
import '../layouts/inline-blocks.css';
import '../layouts/stack.css';

import './App.css'


export default function App() {

    return (
        <div class="com-app layouts-stack">
            <h1 class="title">
                <img
                    alt="logo"
                    class="logo"
                    src={ favicon }
                />
                OKLCH.eu Palette Builder
            </h1>
            <div class="layouts-inline-blocks">
                <BackgroundChooser />
                <PaletteCellTypeChooser />
            </div>
            <HuesTable />
            <PaletteSave />
            <footer>
                <a  children="Report a bug / request a feature"
                    href="https://codeberg.org/emilis/oklch/issues"
                    target="_blank"
                />
                { ', ' }
                <a  children="Source code"
                    href="https://codeberg.org/emilis/oklch"
                    target="_blank"
                />.
            </footer>
        </div>
    );
}
